import { call, put, takeEvery } from 'redux-saga/effects';
import { getJSON } from '../../shared/util-fetch';

export function* init({ payload }) {
    const size = payload.size || 1;
    const tid = payload.tid || 'DNKRF26C';
    const url = `/api/keen?size=${size}&tid=${tid}`;
    const data = yield call(getJSON, url);

    yield put({ type: 'keen:update', payload: data });
}

export function* initAdvisors({ payload }) {
    const size = payload.size || 1;
    const tid = payload.tid || 'DNKRF26C';
    const url = `/api/keenAdvisors?size=${size}&tid=${tid}`;
    const data = yield call(getJSON, url);

    yield put({ type: 'keen:update', payload: data });
}

export function* root() {
    yield takeEvery('keen:init', init);
    yield takeEvery('keen:initAdvisors', initAdvisors);
}
